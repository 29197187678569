<template>
  <div class="container-fluid position-relative landing-section">
    <div class="row min-vh-100 justify-content-center align-items-center">
      <div class="col-8 col-md-6 col-xl-4">
        <img class="w-100 animate__animated animate__jackInTheBox mb-2" alt="app logo" src="../assets/logo.png">
        <a  href="#intro" class="intro-arrow animate__animated animate__shakeY animate__delay-1s">
          <i class="bi bi-arrow-down "></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>