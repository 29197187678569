<template>
  <div class="col-10 col-md-6 col-lg-4 ">
    <div class="mb-2 text-center slide-up text-md-start animate__animated animate__shakeX" @mouseover="showImg = false" @mouseleave="showImg=true">
      <img v-if="showImg" :src="require(`../assets/profile/${team.img}`)" class="mx-auto ms-md-1 shadow-sm rounded-1 team-img" alt="">
      <img v-else :src="require(`../assets/profile/${team.gif}`)" class="mx-auto ms-md-1 shadow-sm rounded-1 team-img" alt="">
      <div class="bg-light p-1 rounded">
        <div class="py-1"></div>
        <p class="mb-0 fw-bold text-primary">{{ team.name }}</p>
        <p class="mb-0 text-black-50 small">Partner / {{ team.position }}</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      showImg: true
    }
  },
  props: {
    team: {
      type: Object
    },
  },

}
</script>

<style scoped>

</style>