<template>
  <Landing />
  <div class="container" id="intro">
    <div class="row justify-content-center py-4">
      <div class="col-12 col-md-8 col-lg-5">
        <div class="mb-2 mb-md-0  text-center">
          <p class="h3 fw-bold mb-1 slide-up text-primary">
            Patriot Games is a tabletop game publishing studio
            based in Eden Prairie, Minnesota
          </p>
          <p class="text-black-50 slide-up">
            We create and distribute analog games that increase
            the general level of happiness in whatever environment
            they’re played in.
          </p>
        </div>
      </div>
      <div class="col-12"></div>
      <div class="col-lg-6">
        <h2 class="fw-bold text-center mx-2 mx-md-0 slide-up">
          If we make you laugh out loud, we’ve done our job.
        </h2>

        <router-link class="btn btn-lg btn-outline-primary slide-up px-2 mb-2 d-none" to="/team">See Team</router-link>
      </div>
      <div class="col-12"></div>
      <div class="col-12 col-md-10 col-lg-8 d-none">
        <img class="w-100 rounded-2 shadow mb-2 slide-up" src="../assets/family-game.jpg" alt="Family Picture Patriot Games">
        <div class="text-center">
          <h3 class="text-primary fw-bold mb-1 slide-up">We’re proud to introduce our first family of titles:</h3>
          <a href="http://playfishfry.com/" class="btn btn-lg btn-secondary text-white px-2 slide-up">
            <i class="bi bi-joystick"></i>
            Fish Fry
          </a>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import ScrollReveal from "scrollreveal";
import Landing from "@/components/LandingSection";

export default {
  name: 'HomeView',
  components: {Landing},
  mounted() {
    var slideUp = {
      distance: '100px',
      origin: 'bottom',
      interval : 300,
      duration : 1500,
      // opacity: true
    };

    ScrollReveal().reveal('.slide-up', slideUp);
  }
}
</script>
