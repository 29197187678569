import { createStore } from 'vuex'

export default createStore({
  state: {
    teams : [
      {
        name : "Brady",
        slug : "brady",
        position : "Boss",
        img: "brady.jpg",
        gif : "gif-brady.gif",
        detail : `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur deserunt dolores eligendi, expedita explicabo libero minima quaerat soluta suscipit veniam? Commodi dicta enim est eum fugiat illum, nesciunt perferendis quod!`,
      },
      {
        name : "Paul",
        slug : "paul",
        position : "Boss",
        img: "paul.jpg",
        gif : "gif-paul.gif",
        detail : `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur deserunt dolores eligendi, expedita explicabo libero minima quaerat soluta suscipit veniam? Commodi dicta enim est eum fugiat illum, nesciunt perferendis quod!`,
      },
      {
        name : "Nick",
        slug : "nick",
        position : "Boss",
        img: "nick.jpg",
        gif : "gif-nick.gif",
        detail : `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur deserunt dolores eligendi, expedita explicabo libero minima quaerat soluta suscipit veniam? Commodi dicta enim est eum fugiat illum, nesciunt perferendis quod!`,
      },
    ]
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
