<template>
  <div class="container my-2" id="team">
    <div class="row justify-content-center">
      <div class="col-12 col-md-6 col-lg-5">
        <div class="mb-3 text-center">
          <p class="h3 fw-bold mb-1 slide-up text-primary">
            Our Team Members
          </p>
          <p class="text-black-50 slide-up small text-secondary">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur laborum quo voluptates. A at aut dolores facere fugit, id in incidunt labore magnam magni mollitia odio omnis quae quasi unde?
          </p>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <TeamCard v-for="(team,index) in teams" :key="index" :team="team" />
      <div class="col-12">
        <div class="text-center">
          <router-link class="btn btn-lg btn-outline-primary slide-up px-2 mb-2" to="/">Go Home</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import TeamCard from "@/components/TeamCard";

export default {
  components: {TeamCard},
  computed: mapState({
    teams : state => state.teams,
  }),
}
</script>

<style scoped>

</style>